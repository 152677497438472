import * as yup from 'yup';


export const commonTariffCodeValidation: { [x: string]: any; } = {
    tariffSchemeCode: yup.string().required("Missing"),
    tariffCodeDesc: yup.string().required("Missing"),
    tariffCode: yup.string().required("Missing"),
    tariffType: yup.string().required("Missing"),
    tariffSeq1: yup.string().required("Missing"),
    tariffSeq2: yup.string().required("Missing"),
    tariffSeq3: yup.string().required("Missing"),
    tariffNatureList: yup.array().required("Missing"),
};

export const createTariffCodeValidation: { [x: string]: any; } = {
  ...commonTariffCodeValidation,
};

export const updateTariffCodeValidation: { [x: string]: any; } = {
  ...commonTariffCodeValidation,
};

export const createTariffCodeValidationSchema = yup.object().shape(createTariffCodeValidation);
export const updateTariffCodeValidationSchema = yup.object().shape(updateTariffCodeValidation);
