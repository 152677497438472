import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeDetailVM } from "presentation/hook/TariffCode/useTariffCodeDetailVM";
import { HeaderWithBackButton } from "presentation/view/components/HeaderWithBackButton/HeaderWithBackButton";
import { memo } from "react";
import { HPHBreadcrumb } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffCodeDetailTitleBar: React.FC = () => {
    const tariffCodeDtlVM = useTariffCodeDetailVM();

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderWithBackButton callback={tariffCodeDtlVM.onCloseClick}>
        <Breadcrumb>
        <HPHBreadcrumb breadcrumbData={[{title: TariffCodeConstant.Tariff.TARIFF},{ title: TariffCodeConstant.Title.TARIFF_CODE_MAIN }]} onCurrentClick={()=>{}}></HPHBreadcrumb>
        </Breadcrumb>
        </HeaderWithBackButton>
    </Sidebarheader>
}

export default memo(TariffCodeDetailTitleBar);