import { TariffComponentValueEntity } from "domain/entity/TariffCode/TariffComponentValueEntity";
import { TariffComponentValueRequest } from "domain/entity/TariffCode/TariffComponentValueRequest";
import _ from "lodash";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffComponentValueRepository } from "./TariffComponentValueRepo";

export const TariffComponentValueRepoImpl = (): TariffComponentValueRepository => {
    const urlSearch = '/v1/tariffComponentValue/search';

    const getTariffComponentValues = async(request: TariffComponentValueRequest) : Promise<TariffComponentValueEntity[]> => {
        return axiosPostData(tariffAxiosInstance, `${urlSearch}`, request).then(res => {
            return _.sortBy(res.data, ["tariffCompValueCode"]);
        }).catch(err => {
            return [];
        });
    }


    return {
        getTariffComponentValues: getTariffComponentValues,
    }
}