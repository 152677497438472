import { calculateStateColor, defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { cellRenderWithColorAndCircle } from "presentation/view/components/TableWrapper/TableCellRender";
import { ManualChargeConstant } from "./ManualChargeConstant";
import { DECIMAL_2 } from "presentation/utils/numberUtil";

const MAN_CHG_HDR_CONSTANT = ManualChargeConstant.Header;
const MAN_CHG_DTL_CONSTANT = ManualChargeConstant.Detail;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
let numberFieldList:string[] = [];
export const INITIAL_MAN_CHARGE_DTL_COL_DEF: any[] = [
    {
        headerName: MAN_CHG_HDR_CONSTANT.STATE,
        field: 'dtlState',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAR_DESC,
        field: 'tariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CNTR_NO,
        field: 'cntrList',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.CHARGE_ON,
        field: 'chargeOnCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.CHG_FROM_DATE,
        field: 'fromDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "dateTime"
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.CHG_TO_DATE,
        field: 'toDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: "dateTime"
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.CHARGE_STO_QTY,
        field: 'unitPerQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.LOG_NO,
        field: 'logNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.CHARGE_QTY,
        field: 'chargeQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.UOM,
        field: 'individualChargeQtyUom',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.ADDITION_QTY,
        field: 'additionalChargeQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.ONE_OFF_RATE,
        field: 'rate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.COMMON_ACC,
        field: 'chargeSharePercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_TAR_TYPE,
        field: 'vatTarType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_TAR_CODE,
        field: 'vatTarCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_TAR_DESC,
        field: 'vatTariffCodeDesc',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PERCENTAGE,
        field: 'vatPercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_CODE,
        field: 'vatOneOffTaxCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TX_TYPE,
        field: 'vatTransactionType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.ARTICLE_STATEMENT_CODE,
        field: 'vatArticleStatementCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.ARTICLE_STATEMENT,
        field: 'vatArticleStatement',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PAYMENT_TERM_IN,
        field: 'vatDays',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PAYMENT_TERM_CR,
        field: 'vatDaysCredit',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PAYMENT_TERM_DEBIT,
        field: 'vatDaysDebit',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 300,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PAYMENT_REF_TERM_IN,
        field: 'paymentReferenceDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 350,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PAYMENT_REF_TERM_CR,
        field: 'paymentReferenceDateCredit',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 350,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TAX_PAYMENT_REF_TERM_DEBIT,
        field: 'paymentReferenceDateDebit',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 350,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.REF_NO_ADJ_FROM,
        field: 'adjRefNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.INV_NO,
        field: 'invoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.CREDIT_NOTE_NO,
        field: 'creditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.DRAFT_INV_NO,
        field: 'draftInvoiceNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_HDR_CONSTANT.DRAFT_CREDIT_NOTE_NO,
        field: 'draftCreditNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.CHARGE_IND,
        field: 'chargeInd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.FW_CODE,
        field: 'forwarderCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.EMPTY_IND,
        field: 'emptyIndicator',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.PARTNER,
        field: 'partnerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.TS_STATUS,
        field: 'cntrTspStatus',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.OP_TYPE,
        field: 'operationType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.SHIFT_CODE,
        field: 'shiftCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.SURCHARGE,
        field: 'chargeSharePercentage',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
        dataType: DECIMAL_2,
        cellStyle: { textAlign: 'right' },
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.REASON_CODE,
        field: 'reasonCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    },
    {
        headerName: MAN_CHG_DTL_CONSTANT.EX_MSG,
        field: 'exceptionMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 200,
    },

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    cellRenderers['dtlState'] = cellRenderWithColorAndCircle;

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers, numberFieldList);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    externalFnctions['dtlState'] = calculateStateColor;
    
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions, numberFieldList);
}