import { TariffCodeConstant } from "presentation/constant/TariffCode/TariffCodeConstant"
import { useTariffCodeDetailVM } from "presentation/hook/TariffCode/useTariffCodeDetailVM"
import { useMessageBarVM } from "presentation/hook/useMessageBar"
import { useTariffCodeDetailTracked } from "presentation/store/TariffCode/TariffCodeDetailProvider"
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer"
import { useCallback, useMemo, useState } from "react"
import { CommonField, FieldType, HPHButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core"
import { Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled"

export const TariffCodeComponentPanel = () => {
    const [tariffCodeDtlState] = useTariffCodeDetailTracked();
    const tariffCodeDtlVM = useTariffCodeDetailVM();
    const messageBarVM = useMessageBarVM();    
    const [isLoading, setIsLoading] = useState(false);
    const { isAdd, isRead, isSaveClicked, allFormState } = tariffCodeDtlState.viewChangeState;
    const currentTarCodeComp = tariffCodeDtlState.currentSelectItem;
    const dynamicOptions = tariffCodeDtlState.dynamicOptions;
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Table;

    const tariffComponentValueDropdownOptions = useMemo(() => {  
        return currentTarCodeComp?.tariffCompCode  
            ? dynamicOptions.tariffComponentValueDropdownOptions[currentTarCodeComp.tariffCompCode]  
            : [];  
    }, [currentTarCodeComp?.tariffCompCode, dynamicOptions.tariffComponentValueDropdownOptions]);


    const handleCancelClick = () => {
        tariffCodeDtlVM.onCancelDetail();
    }

    const handleSaveClick = useCallback(()  => {
        setIsLoading(true);
        tariffCodeDtlVM.onSaveClicked();
        tariffCodeDtlVM.onTempSaveDetail(currentTarCodeComp).then((res) => {
            setIsLoading(false);
            if(res){
                messageBarVM.showError(res);
            }
            
        }).catch((error) =>{
            setIsLoading(false);
            messageBarVM.showError(error.message);
        })

    },[currentTarCodeComp, messageBarVM, tariffCodeDtlVM])

    const memoTarComp = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentTarCodeComp?.tariffCompCode || ''}
                fieldValue={currentTarCodeComp?.tariffCompCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_COMP}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCompCode'}
                options={dynamicOptions.tariffComponentDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeDtlVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [TARIFF_CODE_CONSTANT.TARIFF_COMP, allFormState, currentTarCodeComp?.tariffCompCode, dynamicOptions.tariffComponentDropdownOptions, isAdd, isSaveClicked, tariffCodeDtlVM])

    const memoTarCompValue = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"250px", marginBottom:"24px"}}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentTarCodeComp?.tariffCompValueCode || ''}
                fieldValue={currentTarCodeComp?.tariffCompValueCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_COMP_VAL}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCompValueCode'}
                options={tariffComponentValueDropdownOptions}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeDtlVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
    , [TARIFF_CODE_CONSTANT.TARIFF_COMP_VAL, allFormState, currentTarCodeComp?.tariffCompValueCode, isAdd, isSaveClicked, tariffCodeDtlVM, tariffComponentValueDropdownOptions])

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{'NEW TARIFF COMPONENT'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            
            <div className={'add-edit-form'} style={{ height:'75vh', maxHeight:'75vh' , overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoTarComp}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoTarCompValue}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={isAdd&&!isRead?'Cancel':'Close'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        {isAdd&&<HPHButton label={'Add'} size={'Small'} theme={'Primary'} onClick={()=>handleSaveClick()} />}
                    </>
                }
            </div>
        </div>
    )
}

