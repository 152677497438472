import { TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import _ from "lodash";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffCodeComponentRepository } from "./TariffCodeComponentRepo";

export const TariffCodeComponentRepoImpl = (): TariffCodeComponentRepository => {
    const url = '/v1/tariffCodeComponent/tariffCodeId';

    const getTariffCodeComponentByTariffCodeId = async(tariffCodeId: number) : Promise<TariffCodeComponentEntity[]> => {
        return axiosGetData(tariffAxiosInstance, `${url}/${tariffCodeId}`).then(res => {
            return _.sortBy(res.data, ["tariffCompCode","tariffCompValueCode"]);
        }).catch(err => {
            return [];
        });
    }


    return {
        getTariffCodeComponentByTariffCodeId: getTariffCodeComponentByTariffCodeId,
    }
}