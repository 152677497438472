export const TariffCodeConstant = {
    Tariff:{
        TARIFF: "Tariff",
    },
    Title: {
        TARIFF_CODE_MAIN: "Tariff Code Maintenance",
        UPLOAD: "Upload",
    },

    Table: {
        SEQ: "Seq",
        TARIFF_TYPE: "Tariff Type",
        TARIFF_CODE: "Tariff Code",
        PARENT_TARIFF_TYPE: "Parent Tariff Type",
        PARENT_TARIFF_CODE: "Parent Tariff Code",
        TARIFF_GROUP_ID_ONE: "Tariff Group ID (1)",
        TARIFF_GROUP_ID_TWO: "Tariff Group ID (2)",
        TARIFF_GROUP_ID_THREE: "Tariff Group ID (3)",
        TARIFF_SEQ_ONE: "Tariff Seq (1)",
        TARIFF_SEQ_TWO: "Tariff Seq (2)",
        TARIFF_SEQ_THREE: "Tariff Seq (3)",
        TARIFF_SCHEME: "Tariff Scheme",
        TARIFF_NATURE: "Tariff Nature",
        PERCENTAGE: "percentage",
        TARIFF_CODE_DESC: "Tariff Code Desc",
        TARIFF_CODE_DESCOTHER: "Tariff Code Desc(Other)",
        INCLUDE_IN_THROUGHPUT: "Include in Throughput",
        ACTIVE_IND: "Active Ind",

        TARIFF_COMP: "Tariff Component",
        TARIFF_COMP_DESC: "Tariff Component Desc.",
        TARIFF_COMP_VAL: "Tariff Component Value",
        TARIFF_COMP_VAL_DESC: "Tariff Component Value Desc.",

    },

    Search: {
        SEARCH_CRITERIA: "Search Criteria",
        COMPANY_CODE: "Company Code",
        PROPOSAL_NO: "Proposal No.",
        OPERATING_TML: "Operating Tml.",
        STATUS: "Status",
        EFFECTIVE_DATE_RANGE: "Effective Date Range",
    }


}

export const tariffCodeMainRequiredFieldList: string[] = [
    'tariffType',
    'tariffCode',
    'tariffCodeDesc',
    'tariffSeqOne',
    'tariffSeqTwo',
    'tariffSeqThree',
    'activeInd',
];
