import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { EMPTY_TARIFF_CODE_COMP_ENTITY, TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffCodeComponentRepository } from "domain/repository/TariffCode/TariffCodeComponentRepo";
import { TariffComponentRepository } from "domain/repository/TariffCode/TariffComponentRepo";
import { TariffComponentValueRepository } from "domain/repository/TariffCode/TariffComponentValueRepo";
import _ from "lodash";
import { createTariffCodeValidationSchema, updateTariffCodeValidationSchema } from "presentation/constant/TariffCode/TariffCodeValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { TariffCodeDetailModel } from "presentation/model/TariffCode/TariffCodeDetailModel";
import { TariffCodeModel } from "presentation/model/TariffCode/TariffCodeModel";
import { Dispatch, SetStateAction } from "react";
import { GroupCheckboxList, IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface TariffCodeDetailVMProps extends BaseViewModel {
    dtlDispatch: [
        Dispatch<SetStateAction<TariffCodeDetailModel>> | ((value: SetStateAction<TariffCodeDetailModel>) => void),
    ],
    dispatch: [
        Dispatch<SetStateAction<TariffCodeModel>> | ((value: SetStateAction<TariffCodeModel>) => void),
    ],
    standardTariffCodeRepo: StandardTariffCodeRepository,
    tariffComponentRepo: TariffComponentRepository,
    tariffComponentValueRepo: TariffComponentValueRepository,
    tariffCodeComponentRepo: TariffCodeComponentRepository,
    masterDataRepo: MasterDataRepository,
}

export const TariffCodeDetailVM = ({ dtlDispatch,dispatch,standardTariffCodeRepo,tariffComponentRepo, tariffComponentValueRepo,tariffCodeComponentRepo, masterDataRepo }: TariffCodeDetailVMProps) => {
    const [tariffCodeDetailDispatch] = dtlDispatch;
    const [tariffCodeDispatch] = dispatch;

    const loadDropdownOption = async () => {


        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {
                
                let newTariffs = _.orderBy(tariffs, ["tariffType","tariffCode"]);                
                let tariffTypeDropdownOptions:DropdownProps[] = [];
                let tariffCodeDropdownOptions:{[key:string]: DropdownProps[]} = {};

                newTariffs.forEach(tariff => {
                    const isTariffTypeExisted = tariffTypeDropdownOptions.find(t => 
                        t.value === tariff.tariffType);
                    if(!isTariffTypeExisted){
                        tariffTypeDropdownOptions.push({
                            dropdownLabel: tariff.tariffType,
                            tagLabel: tariff.tariffType,
                            value: tariff.tariffType,
                        })
                    }

                    if(tariff.tariffCode){
                        if(!tariffCodeDropdownOptions[tariff.tariffType]){
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })
                    }
                })            

                tariffCodeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOptions,
                        parentTariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await tariffComponentRepo.getAllActiveTariffComponents().then(
            tarComps => {

                let tariffComponentDropdownOptions:DropdownProps[] = [];
                let tariffComponentValueDropdownOptions:{[key:string]: DropdownProps[]} = {};

                tarComps.forEach(comp =>{
                    tariffComponentDropdownOptions.push({
                        dropdownLabel: comp.tariffCompCode,
                        tagLabel: comp.tariffCompCode,
                        value: comp.tariffCompCode,
                    })

                    let tarCompValDropdownOptions = comp.componentValues?.map((compVal) => ({
                        dropdownLabel: compVal.tariffCompValueCode,
                        tagLabel: compVal.tariffCompValueCode,
                        value: compVal.tariffCompValueCode,
                    })) ?? [];

                    tariffComponentValueDropdownOptions[comp.tariffCompCode] = tarCompValDropdownOptions;

                })

                tariffCodeDetailDispatch(prevState =>({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffComponentDropdownOptions: tariffComponentDropdownOptions,
                        tariffComponentValueDropdownOptions: tariffComponentValueDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey('TariffNatureEntity').then(
            tariffNatures => {
                let tariffNatureDropdownOptions = tariffNatures?.map((entity) => ({
                    dropdownLabel: entity.code,
                    tagLabel: entity.code,
                    value: entity.code,
                })) ?? []

                tariffCodeDetailDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffNatureDropdownOptions: tariffNatureDropdownOptions,
                    }
                }))
            }
        )

        await standardTariffCodeRepo.getTariffScheme().then(
            tarSchemes => {

               const  tariffSchemeDropdownOptions = tarSchemes?.map((scheme) => ({
                    dropdownLabel: scheme.tariffSchemeCode,
                    tagLabel: scheme.tariffSchemeCode,
                    value: scheme.tariffSchemeCode,
                })) ?? [];

                tariffCodeDetailDispatch(prevState =>({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffSchemeDropdownOptions: tariffSchemeDropdownOptions
                    }
                }))
            }
        )

        

    }

    const onPageInit = (currentTarCodeEty: StandardTariffCodeEntity | null) => {

        const tarCodeEty = currentTarCodeEty ?? EMPTY_STANDARD_TARIFF_CODE_ENTITY;

        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                currentTariffCodeEntity: { ...tarCodeEty },
                editingTariffCodeEntity: {...tarCodeEty },
                currentSelectItem: {...EMPTY_TARIFF_CODE_COMP_ENTITY},
                viewChangeState:{
                    ...prevState.viewChangeState,
                    isAdd: currentTarCodeEty?.id === null,
                    isEditable: !!(currentTarCodeEty?.id) ,
                    isRead: currentTarCodeEty?.id !== null,
                }
            }
        })
    }

    const onHeaderSingleDropdownChange = (e: any, fieldName: string) => {
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                editingTariffCodeEntity: {
                    ...prevState.editingTariffCodeEntity,
                    [fieldName]: e.value,
                }
            }
        });
    };

    const onHeaderMultipleDropdownChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }
        tariffCodeDetailDispatch(prevState => ({
            ...prevState,
            editingTariffCodeEntity: {
                ...prevState.editingTariffCodeEntity,
                [fieldKey]: val,
            }
        }));
    };

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                editingTariffCodeEntity: {
                    ...prevState.editingTariffCodeEntity,
                    [fieldKey]: val
                },
            }
        })
    }
    
    const onFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSelectItem: {
                    ...prevState.currentSelectItem,
                    [fieldKey]: val
                },
            }
        })
    }

    const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){
            return tariffCodeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    editingTariffCodeEntity: {
                            ...prevState.editingTariffCodeEntity,
                            [fieldName]: e.key,
                        },
                    }          
                })
        }
    };


    const updateSelectedRows = async (rows: any[]) => {
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSelectItem: rows[0],
            }
        })
    }

    const onResetClick = async () => {
        tariffCodeDetailDispatch(prevState => {
            let tempTarCodeEty: StandardTariffCodeEntity = EMPTY_STANDARD_TARIFF_CODE_ENTITY;
            if(!prevState.viewChangeState.isAdd){
                tempTarCodeEty = prevState.currentTariffCodeEntity
            }

            console.log('@@@',tempTarCodeEty)

            return {
                ...prevState,
                editingTariffCodeEntity: {...tempTarCodeEty},
            }
        })
    }

    const onCloseClick = () => {
        tariffCodeDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackFromDetail:true,
                isAllowAutoSearch:true,
                selectedRows:[],
                currentSelectedRow: {...EMPTY_STANDARD_TARIFF_CODE_ENTITY},
            }
        }); 
    }

    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[],fieldName:string) => {
        let selectedValue:string[] = [];
        if (e) {
            e.forEach(function(value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        tariffCodeDetailDispatch(prevState => ({
            ...prevState,
            editingTariffCodeEntity : {
                ...prevState.editingTariffCodeEntity,
                [fieldName]: selectedValue,
            }
        }));
    };

    const onAdd = () => {
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,  
                currentSelectItem: {
                    ...EMPTY_TARIFF_CODE_COMP_ENTITY,
                    tariffCompCode: '',
                    tariffCompValueCode: ''
                },           
                isShowDetailInfo: true,
                viewChangeState: {
                    ...prevState.viewChangeState,
                    isAdd: true,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onRowClick = (row: TariffCodeComponentEntity) => {
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSelectItem: row,
                isShowDetailInfo: true,
                viewChangeState: {
                    ...prevState.viewChangeState,
                    isAdd: false,                    
                    allFormState: {},
                }                        
            }
        });
    }

    const onCancelDetail = () => {
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                isShowDetailInfo: false,
            }
        })
    }

    const onSaveClicked =() => {
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                viewChangeState: {
                    ...prevState.viewChangeState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSearch = async (tariffCodeId: number) => {

        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                tariffCodeComponentList: [],
                selectedRows: [],
            }
        })

        tariffCodeComponentRepo.getTariffCodeComponentByTariffCodeId(tariffCodeId).then((data) => {
            tariffCodeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    tariffCodeComponentList: data,
                    selectedRows: [],
                    currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                    editingTariffCodeEntity: EMPTY_STANDARD_TARIFF_CODE_ENTITY,
                }
            })
        })
    }

    const onTempSaveDetail = async (currentTarCodeComp: TariffCodeComponentEntity) => {
        const compCode = currentTarCodeComp.tariffCompCode;
        const compValCode = currentTarCodeComp.tariffCompValueCode;
        let msg = 'Error when save data.'
        if(!!compCode && !!compValCode){
            // check illegality
            const valResult = await tariffComponentValueRepo.getTariffComponentValues({
                searchCriteria: {
                    tariffCompCode: compCode,
                    tariffCompValueCode: compValCode,
                }
            })

            if(_.isEmpty(valResult)){
                msg = 'Invalid Tariff Component Code & Value.';
            }else{
                return tariffCodeDetailDispatch(prevState => {
                    const tarCompVal = valResult[0];
                    const prevTarCodeCompList = prevState.tariffCodeComponentList;
                    let data = null;

                    let tarCodeCompEntity: TariffCodeComponentEntity = {
                        ...EMPTY_TARIFF_CODE_COMP_ENTITY,
                        seq: tarCompVal.seq,
                        tariffCompId: tarCompVal.tariffCompId??0,
                        tariffCompCode: tarCompVal.tariffCompCode,
                        tariffCompDesc: tarCompVal.tariffCompDesc,
                        tariffCompValueId: tarCompVal.id??0,
                        tariffCompValueCode: tarCompVal.tariffCompValueCode,
                        tariffCompValueDesc: tarCompVal.tariffCompValueDesc,
                    }

                    if(_.isEmpty(prevTarCodeCompList)){
                        data = Array.of(tarCodeCompEntity)
                    }else{
                        const filteredList = prevTarCodeCompList.filter(val => val.tariffCompCode !== tarCompVal.tariffCompCode 
                            && val.tariffCompValueCode !== tarCompVal.tariffCompValueCode)??[];
                        filteredList.push(tarCodeCompEntity);

                        data = _.sortBy(filteredList, ["seq"]);
                    }

                    console.table(data)
                    return {
                        ...prevState,
                        tariffCodeComponentList: data,
                        selectedRows: [],
                        currentSelectItem: EMPTY_TARIFF_CODE_COMP_ENTITY,
                        isShowDetailInfo: false,
                    }
                })
            }
        }else{
            msg = 'Please input Tariff Compoent Code & Value';
        }

        return msg;
    }

    const onGenerate = () => {
        tariffCodeDetailDispatch(prevState => {
            const tarCodeCompList = prevState.tariffCodeComponentList;
            let tariffCode = '';
            let tariffCodeDesc = '';
            let tariffCompValIdList: number[]= [];
            if(!_.isEmpty(tarCodeCompList)){
                tariffCode = tarCodeCompList?.map(e => e.tariffCompValueCode).join('');
                tariffCodeDesc = tarCodeCompList?.map(e => e.tariffCompValueDesc).join(' ');
                tariffCompValIdList = tarCodeCompList?.map(e => e.tariffCompValueId as number)??[];
            }

            return {
                ...prevState,
                editingTariffCodeEntity: {
                    ...prevState.editingTariffCodeEntity,
                    tariffCode: tariffCode,
                    tariffCodeDesc: tariffCodeDesc,
                    tariffCompValIdList: tariffCompValIdList,
                },
                selectedRows: [],
                isShowDetailInfo: false,
            }
        })
    }

    const onSave =  async(currentTarCodeEty: StandardTariffCodeEntity, isAdd: boolean) => {
        const valHdrResult = await Validation(isAdd?createTariffCodeValidationSchema:updateTariffCodeValidationSchema).ValidateFormOnly(currentTarCodeEty);
        
        if (valHdrResult ) {
            let validatedTariffResult: {[x: string]: string} = {};  
            if (valHdrResult) {  
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };  
            }  

            console.log('validatedTariffResult',validatedTariffResult)

            tariffCodeDetailDispatch(prevState => {
                return {
                    ...prevState,
                    viewChangeState: {
                        ...prevState.viewChangeState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }                        
                }
            });
            return validatedTariffResult;
        } else {

            let res = null;
            if(isAdd){
                res = await standardTariffCodeRepo.createNewStandardTariffCode(currentTarCodeEty);
            }else{
                res = await standardTariffCodeRepo.updateStandardTariffCode(currentTarCodeEty);
            }
            
            if(res.success){    
                onCloseClick();
            }else{
                return res.data;
            }
        }
    }

    const onEdit = (currentTarCodeEty: StandardTariffCodeEntity)=>{
        tariffCodeDetailDispatch(prevState => {
            return {
                ...prevState,
                viewChangeState: {
                    ...prevState.viewChangeState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true, 
                },
                editingTariffCodeEntity:{
                    ...currentTarCodeEty,
                    tariffNatureList: !!!currentTarCodeEty?.tariffNature ? [] : currentTarCodeEty.tariffNature.split(",")?.map(e => e.trim())
                }
            }
        })
    }

    return {
        onPageInit: onPageInit,
        loadDropdownOption: loadDropdownOption,
        onHeaderFieldChange: onHeaderFieldChange,
        onFieldChange: onFieldChange,
        updateSelectedRows: updateSelectedRows,
        onCloseClick: onCloseClick,
        onResetClick: onResetClick,
        onHeaderMultipleDropdownChange: onHeaderMultipleDropdownChange,
        onHeaderSingleDropdownChange: onHeaderSingleDropdownChange,
        onGroupCheckboxChange: onGroupCheckboxChange,
        onRadioChange: onRadioChange,
        onAdd: onAdd,
        onRowClick: onRowClick,
        onSearch: onSearch,
        onCancelDetail: onCancelDetail,
        onSaveClicked: onSaveClicked,
        onTempSaveDetail: onTempSaveDetail,
        onGenerate: onGenerate,
        onSave: onSave,
        onEdit: onEdit,
    }
}