export interface TariffCodeComponentEntity {
    seq: number | null,
    tariffCompId: number | null,
    tariffCompCode: string | null,
    tariffCompDesc: string | null,
    tariffCompValueId: number | null,
    tariffCompValueCode: string | null,
    tariffCompValueDesc: string | null,

    [key: string] : string | number |  null
}

export const EMPTY_TARIFF_CODE_COMP_ENTITY : TariffCodeComponentEntity = {
    seq: null,
    tariffCompId: null,
    tariffCompCode: null,
    tariffCompDesc: null,
    tariffCompValueId: null,
    tariffCompValueCode: null,
    tariffCompValueDesc: null,
}