import { EMPTY_TARIFF_CODE_DETAIL_MODEL } from "presentation/model/TariffCode/TariffCodeDetailModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: TariffCodeDetailProvider,
    useTracked: useTariffCodeDetailTracked
} = createContainer(() => useState(EMPTY_TARIFF_CODE_DETAIL_MODEL), {concurrentMode: true});
export { TariffCodeDetailProvider, useTariffCodeDetailTracked };

