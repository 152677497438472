import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { EMPTY_TARIFF_CODE_COMP_ENTITY, TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffCodeDetailDropdownOptions {
    tariffTypeDropdownOptions: DropdownProps[],
    parentTariffTypeDropdownOptions: DropdownProps[],
    parentTariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffComponentDropdownOptions: DropdownProps[],
    tariffComponentValueDropdownOptions: { [key: string]: DropdownProps[] },
    tariffNatureDropdownOptions: DropdownProps[],
    tariffSchemeDropdownOptions: DropdownProps[],
}
export interface ViewChangeState extends BaseViewChangeSate {
    
}

export interface TariffCodeDetailModel {
    isLoading: boolean,
    dynamicOptions: TariffCodeDetailDropdownOptions,
    currentTariffCodeEntity: StandardTariffCodeEntity, // from main screen
    editingTariffCodeEntity: StandardTariffCodeEntity, // add - new , editing - currentTariffCodeEntity
    isShowDetailInfo: boolean,
    viewChangeState: ViewChangeState,
    tariffCodeComponentList: TariffCodeComponentEntity[],
    currentSelectItem: TariffCodeComponentEntity,
    selectedRows: TariffCodeComponentEntity[],
}

export const EMPTY_TARIFF_CODE_DETAIL_MODEL: TariffCodeDetailModel = {
    isLoading: false,
    currentTariffCodeEntity: { ...EMPTY_STANDARD_TARIFF_CODE_ENTITY },
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        parentTariffTypeDropdownOptions: [],
        parentTariffCodeDropdownOptions: {},
        tariffComponentDropdownOptions: [],
        tariffComponentValueDropdownOptions: {},
        tariffNatureDropdownOptions: [],
        tariffSchemeDropdownOptions: []
    },
    currentSelectItem: { ...EMPTY_TARIFF_CODE_COMP_ENTITY },
    viewChangeState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        }
    },
    editingTariffCodeEntity: { ...EMPTY_STANDARD_TARIFF_CODE_ENTITY },
    isShowDetailInfo: false,
    tariffCodeComponentList: [],
    selectedRows: []
}