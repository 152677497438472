import { EMPTY_STANDARD_TARIFF_CODE_ENTITY, StandardTariffCodeEntity } from "domain/entity/TariffCode/StandardTariffCodeEntity";
import { EMPTY_TARIFF_CODE_SEARCH_CRITERIA, TariffCodeSearchCriteria } from "domain/entity/TariffCode/TariffCodeSearchCriteria";

import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

export interface TariffCodeDropdownOptions {

    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffNatureDropdownOptions: DropdownProps[],

}
export interface TariffCodeChangeState extends BaseViewChangeSate {
}

export interface TariffCodeModel {
    isLoading: boolean,
    isShowDetail: boolean,
    isShowCriteriaPanel: boolean,
    tariffCodeEntityList: StandardTariffCodeEntity[],
    currentSelectedRow: StandardTariffCodeEntity,
    selectedRows: StandardTariffCodeEntity[],
    searchCriteria: TariffCodeSearchCriteria,
    dynamicOptions: TariffCodeDropdownOptions,
    isAllowAutoSearch: boolean,
    isBackFromDetail: boolean,
    subMenuItemArray: MenuItem[],
    isShowMoveToPanel: boolean,
}

export const EMPTY_TARIFF_CODE_MODEL: TariffCodeModel = {
    isLoading: false,
    isShowDetail: false,
    isShowCriteriaPanel: false,
    tariffCodeEntityList: [],
    currentSelectedRow: { ...EMPTY_STANDARD_TARIFF_CODE_ENTITY },
    selectedRows: [],
    searchCriteria: { ...EMPTY_TARIFF_CODE_SEARCH_CRITERIA },
    dynamicOptions: {
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        tariffNatureDropdownOptions: [],
    },
    isAllowAutoSearch: false,
    isBackFromDetail: false,
    subMenuItemArray: [],
    isShowMoveToPanel: false,
}