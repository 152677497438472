export enum Permission {
    BUPROFILE = "UI.AUTH.BUPROFILE",
    LOGIN = "LOGIN",   
    USER = "UI.ADMIN.USER",
    USERGROUP = "UI.ADMIN.USERGROUP",
    USERSETTING = "UI.ADMIN.USERSETTING",
    CHARGE_PROCESSING = "UI.CHAR.CHARGEPROCESSING",
    CHANGE_KEY_DATA = "UI.CHAR.CHANKEYDATAMAIN",
    MANUAL_CHARGE = "UI.CHAR.MANUALCHARGE",
    CHARGE_DEATIL_MAINTENANCE= "UI.CHAR.CHARGEDETAILMAINTENANCE",
    MANUAL_CHARGE_DETAIL = "UI.CHAR.MANUALCHARGEDETAIL",    
    NON_OPS_CHARGE = "UI.CHAR.NONOPSCHARGE",
    NON_OPS_CHARGE_DETAIL = "UI.CHAR.NONOPSCHARGEDETAIL",
    CHARGE_CONFIRMED_MAIN = "UI.CHAR.CHARGECONFIRMED",
    CREDIT_NOTE = "UI.CHAR.CREDITNOTE",
    CREDIT_NOTE_DETAIL = "UI.CHAR.CREDITNOTEDETAIL",
    EMPTY_POOL_STORAGE_GEN = "UI.CHAR.EMPTYPOOLSTORAGEGEN",
    DOCUMENT_APPROVAL = "UI.DOC.DOCUMENTAPPROVAL",
    DOCUMENT_PREVIEW = "UI.CHAR.INVOICE",
    DOCUMENT_PREVIEW_DISAPPROVE = "UI.CHAR.INVOICEDISAPPROVE",
    DOCUMENT_PREVIEW_APPROVE = "UI.CHAR.INVOICEAPPROVE",
    DOCUMENT_PREVIEW_DETAIL = "UI.CHAR.INVOICEDETAIL",
    SUPPLEMENTARY_INVOICE = "UI.CHAR.SUPPLEMENTARYINVOICE",
    SUPPLEMENTARY_INVOICE_DETAIL = "UI.CHAR.SUPPLEMENTARYINVOICEDETAIL",
    DOCUMENT_EDI_MONITORING = "UI.DOC.EDIMONITORING",
    DOCUMENT_ENQUIRY_DETAIL = "UI.DOC.DOCUMENTENQUIRYDETAIL",
    STANDARD_PROPOSAL_MAINTENANCE = "UI.AGREE.STANDARDPROPOSALMAIN",
    // STANDARD_PROPOSAL_REJECT = "UI.AGREE.STANDARDPROPOSALREJECT",
    // STANDARD_PROPOSAL_APPROVE = "UI.AGREE.STANDARDPROPOSALAPPROVE",
    STANDARD_PROPOSAL_DETAIL_MAINTENANCE = "UI.AGREE.STANDARDPROPOSALDETAILMAIN",
    GENERAL_PROPOSAL_MAINTENANCE = "UI.AGREE.GENERALPROPOSALMAIN",
    GENERAL_PROPOSAL_DETAIL_MAINTENANCE = "UI.AGREE.GENERALPROPOSALDETAILMAIN",
    TARIFF_CODE_MAINTENANCE = "UI.TARIFF.TARIFFCODEMAIN",
    TARIFF_CODE_DETAIL_MAINTENANCE = "UI.TARIFF.TARIFFCODEDETAILMAIN",
}