import { useTariffCodeDetailTracked } from "presentation/store/TariffCode/TariffCodeDetailProvider";
import { memo } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffCodeComponentPanel } from "./Form/TariffCodeComponentPanel";
import TariffCodeComponentTablePanel from "./Table/TariffCodeComponentTablePanel";

export const TariffCodeDetailContentPanel = () => {
    const [tariffCodeDetailState] = useTariffCodeDetailTracked();
    const {isShowDetailInfo} = tariffCodeDetailState;

    return <div className={`main-comp-wrapper${isShowDetailInfo ? '' : ' im-hide-side-form-draggable'}`}>
            <SliderPanel
                isOpen={isShowDetailInfo}
                leftSectionWidth={isShowDetailInfo ? '70%' :'30%'}
                rightSectionWidth={isShowDetailInfo ? '30%' : '70%'}
                draggable={true}
                leftChildren={<TariffCodeComponentTablePanel/>}
                rightChildren={<TariffCodeComponentPanel/>} />
        </div>
}

export default memo(TariffCodeDetailContentPanel);