import { TariffComponentEntity } from "domain/entity/TariffCode/TariffComponentEntity";
import _ from "lodash";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import tariffAxiosInstance from "../axios/tariffAxiosInstance";
import { TariffComponentRepository } from "./TariffComponentRepo";

export const TariffComponentRepoImpl = (): TariffComponentRepository => {
    const urlActive = '/v1/tariffComponentActive';

    const getAllActiveTariffComponents = async() : Promise<TariffComponentEntity[]> => {
        return axiosGetData(tariffAxiosInstance, `${urlActive}`, []).then(res => {
            return _.sortBy(res.data, ["seq"]);
        }).catch(err => {
            return [];
        });
    }


    return {
        getAllActiveTariffComponents: getAllActiveTariffComponents,
    }
}