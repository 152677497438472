import { SelectionChangedEvent } from "ag-grid-community";
import { TariffCodeComponentEntity } from "domain/entity/TariffCode/TariffCodeComponentEntity";
import _ from "lodash";
import { transferRowData } from "presentation/constant/ManualCharge/ManChargeDtlColumnDefinition";
import { INITIAL_TARIFF_CODE_COMP_COL_DEF } from "presentation/constant/TariffCode/TariffCodeComponentColumnDefinition";
import { useTariffCodeDetailVM } from "presentation/hook/TariffCode/useTariffCodeDetailVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeDetailTracked } from "presentation/store/TariffCode/TariffCodeDetailProvider";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHButton, HPHTable, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const TariffCodeComponentTablePanel:React.FC = () => {
    const [tariffCodeDtlState] = useTariffCodeDetailTracked();;
    const tariffCodeDtlVM = useTariffCodeDetailVM();
    const {tariffCodeComponentList, isShowDetailInfo} = tariffCodeDtlState;
    const {isAdd} = tariffCodeDtlState.viewChangeState;
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const {allowCreate,allowUpdate} = anainfoState;
    let gridRef: any = useRef();

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_TARIFF_CODE_COMP_COL_DEF.slice());
            
        gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        tariffCodeDtlVM.updateSelectedRows(selectedRows);
      }, [tariffCodeDtlVM])

    const handleRowClick = useCallback((tarCodeComp: TariffCodeComponentEntity) => {
        tariffCodeDtlVM.onRowClick(tarCodeComp);
    }, [tariffCodeDtlVM])

    const handleDelete = useCallback(async(tarCodeComp: TariffCodeComponentEntity) => {
    }, [])

    const handleAddClick = useCallback(async() => {
        tariffCodeDtlVM.onAdd();      
    },[tariffCodeDtlVM])

    const handleGenerate = useCallback(()=>{
        tariffCodeDtlVM.onGenerate();
    },[tariffCodeDtlVM])

    // const tbBtns = useMemo(()=>{
    //     let btns = [];
    //     if(allowCreate && isAdd){
    //         btns.push({
    //             id: 'onGenerateButton',
    //             icon: 'Icon-fast',
    //             title: 'Generate'
    //         })
    //     }
    //     return btns;
    // },[allowCreate, isAdd])
    
    const isDisableGenerate = () => {
        return !isAdd || _.isEmpty(tariffCodeComponentList) || isShowDetailInfo;
    }

    const memoTariffCodeComponentTable = useMemo(() => {
        return <HPHTable
            id='TariffCodeComponent-table'
            columns={INITIAL_TARIFF_CODE_COMP_COL_DEF.slice()}
            onRowClick={(e: any) => handleRowClick(e.data)}
            //headerActionButtons={tbBtns}
            data={transferRowData(tariffCodeComponentList??[])}
            showPaginator={false}
            editable={false}
            showAddIcon={allowCreate && isAdd}
            showDeleteButton={false}
            showReloadIcon={false}
            //onGenerateButton={handleGenerate}
            isScrollHighlighted={true}
            selectionMode={false}
            rowSelection={"multiple"}
            onAddClick={handleAddClick}
            onSelectionChanged={handleSelectionChange}
            onDelete={(deleteTarComp: any) => handleDelete(deleteTarComp)}
            gridHeight="customHeight"
            customHeight="calc(100vh - 500px)"
            ref={gridRef} />;
    }, [tariffCodeComponentList, allowCreate, isAdd, handleAddClick, handleSelectionChange, handleRowClick, handleDelete])


    if (tariffCodeDtlState.isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>{ <TableWrapper>{memoTariffCodeComponentTable}</TableWrapper>}
        {
              <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
                <StyledAction className="tm-animated-wrapper">
                    {(allowUpdate) && <HPHButton disabled={isDisableGenerate()} label={"Generate"} size={'Small'} theme={'Primary'} onClick={handleGenerate} />}
                </StyledAction>
              </Sidebarheader>
            }
    </>;
}

export default memo(TariffCodeComponentTablePanel);