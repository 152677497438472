import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { TariffCodeDetailProvider } from "presentation/store/TariffCode/TariffCodeDetailProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import TariffCodeDetailMaintenance from "presentation/view/section/TariffCode/Detail/TariffCodeDetailMaintenance";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const TariffCodeDetailContMain = () => {
    return <ANAInfoWrapper permission={Permission.TARIFF_CODE_DETAIL_MAINTENANCE}>
        <MessageBarWrapper>
            <TariffCodeDetailProvider>
                <GridStyles/>
                <TariffCodeDetailMaintenance/>
            </TariffCodeDetailProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default TariffCodeDetailContMain;