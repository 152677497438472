import { yesOrNoRadioOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { TariffCodeConstant, tariffCodeMainRequiredFieldList } from "presentation/constant/TariffCode/TariffCodeConstant";
import { useTariffCodeDetailVM } from "presentation/hook/TariffCode/useTariffCodeDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffCodeDetailTracked } from "presentation/store/TariffCode/TariffCodeDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useMemo, useState } from "react";
import { CommonField, FieldType, HPHGroupRadioButton, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionCross, Sidebarheader, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const TariffCodeHeaderFormPanel = () => {
    const [tariffCodeDtlState] = useTariffCodeDetailTracked();
    const tariffCodeDtlVM = useTariffCodeDetailVM();
    const messageBarVM = useMessageBarVM();
    const {currentTariffCodeEntity,editingTariffCodeEntity, viewChangeState, dynamicOptions} = tariffCodeDtlState;
    const {isAdd,isEditable,isRead,isSaveClicked,allFormState} = viewChangeState;
    const TARIFF_CODE_CONSTANT = TariffCodeConstant.Table;
    
    const [anainfoState] = useANAInfoTracked();
    const {allowUpdate} = anainfoState;

    const currentEntity = isRead? currentTariffCodeEntity : editingTariffCodeEntity;

    const [isLoading, setIsLoading] = useState(false);
    const handleReset = useCallback(async () => {
        tariffCodeDtlVM.onResetClick();
    }, [tariffCodeDtlVM]);

    const memoParentTariffTypeOptions = useMemo(() => {  
        return currentEntity.tariffType  
            ? Array.of({
                dropdownLabel: currentEntity.tariffType,
                tagLabel: currentEntity.tariffType,
                value: currentEntity.tariffType,
            })  
            : [];  
    }, [currentEntity.tariffType]);

    const memoParentTariffCodeOptions = useMemo(() => {  
        return currentEntity.parentTariffType  
            ? dynamicOptions.parentTariffCodeDropdownOptions[currentEntity.parentTariffType]  
            : [];  
    }, [currentEntity.parentTariffType, dynamicOptions.parentTariffCodeDropdownOptions]);


    const onNumberFieldChange = useCallback((e:any, fieldName:string, fieldValue:any) => {
        tariffCodeDtlVM.onHeaderFieldChange(fieldName, fieldValue);
    }, [tariffCodeDtlVM]);

    
    const memoSeq = useMemo(() =>
        <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
            <CommonField
                errorMessages={{}}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentEntity?.seq?.toString() || ''}
                //fieldValue={currentEntity?.seq?.toString()}
                fieldLabel={TARIFF_CODE_CONSTANT.SEQ}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'seq'}
                isShowMissingError={false}
                maxLength={60}
                requiredFieldList={[]}
                onFieldChange={()=>{}} />        
        </div>
    , [TARIFF_CODE_CONSTANT.SEQ, currentEntity?.seq, isSaveClicked])   

    // const memoActiveInd = useMemo(() =>
    //     <>
    //     <div className='im-flex-row-item'>
    //         <HPHGroupCheckbox
    //             label={TARIFF_CODE_CONSTANT.ACTIVE_IND}
    //             checkboxData={[
    //                 {key:"Y", name:"Y", disabled:false},
    //                 {key:"N", name:"N", disabled:false}
    //             ]}
    //             selectedValues={currentEntity.activeInd}
    //             orientation={"horizontal"}
    //             onChange={(e: (GroupCheckboxList | undefined)[]) => tariffCodeDtlVM.onGroupCheckboxChange(e, "activeInd")}
    //         />
    //     </div>
    //     </>
    // , [TARIFF_CODE_CONSTANT.ACTIVE_IND, currentEntity.activeInd, tariffCodeDtlVM])


    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <CommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffType || ''}
                fieldValue={currentEntity?.tariffType}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={60}
                options={dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
        </div>
        , [allFormState, isRead, currentEntity?.tariffType, TARIFF_CODE_CONSTANT.TARIFF_TYPE, isSaveClicked, dynamicOptions.tariffTypeDropdownOptions, tariffCodeDtlVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCode || ''}
                fieldValue={currentEntity?.tariffCode}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={60}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={()=>{}} />      
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE, allFormState, currentEntity?.tariffCode, isSaveClicked])

        const memoTarCodeDesc = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "600px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffCodeDesc || ''}
                    fieldValue={currentEntity?.tariffCodeDesc}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE_DESC}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffCodeDesc'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />  
        </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_CODE_DESC, allFormState, currentEntity?.tariffCodeDesc, isRead, isSaveClicked, tariffCodeDtlVM])

        const memoTarCodeDescOther = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "600px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffCodeDescOther || ''}
                    fieldValue={currentEntity?.tariffCodeDescOther}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_CODE_DESCOTHER}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffCodeDescOther'}
                    isShowMissingError={false}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />      
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_CODE_DESCOTHER, currentEntity?.tariffCodeDescOther, isRead, isSaveClicked, tariffCodeDtlVM])

        const memoTarGpIdOne = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffGroupId1 || ''}
                    fieldValue={currentEntity?.tariffGroupId1}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_ONE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffGroupId1'}
                    isShowMissingError={false}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />   
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_ONE, currentEntity?.tariffGroupId1, isRead, isSaveClicked, tariffCodeDtlVM])

        const memoTarGpIdTwo = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffGroupId2 || ''}
                    fieldValue={currentEntity?.tariffGroupId2}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_TWO}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffGroupId2'}
                    isShowMissingError={false}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_TWO, currentEntity?.tariffGroupId2, isRead, isSaveClicked, tariffCodeDtlVM])    

        const memoTarGpIdThree = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={{}}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentEntity?.tariffGroupId3 || ''}
                    fieldValue={currentEntity?.tariffGroupId3}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_THREE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffGroupId3'}
                    isShowMissingError={false}
                    maxLength={60}
                    requiredFieldList={[]}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />    
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_GROUP_ID_THREE, currentEntity?.tariffGroupId3, isRead, isSaveClicked, tariffCodeDtlVM])

        const memoTarSeqOne = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSeq1?.toString() || ''}
                    fieldValue={currentEntity?.tariffSeq1?.toString()}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_ONE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffSeq1'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_ONE, allFormState, currentEntity?.tariffSeq1, isRead, isSaveClicked, tariffCodeDtlVM])

        const memoTarSeqTwo = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSeq2?.toString() || ''}
                    fieldValue={currentEntity?.tariffSeq2?.toString()}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_TWO}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffSeq2'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_TWO, allFormState, currentEntity?.tariffSeq2, isRead, isSaveClicked, tariffCodeDtlVM])
        
        const memoTarSeqThree = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSeq3?.toString() || ''}
                    fieldValue={currentEntity?.tariffSeq3?.toString()}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SEQ_THREE}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'tariffSeq3'}
                    isShowMissingError={true}
                    maxLength={60}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                        tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
            , [TARIFF_CODE_CONSTANT.TARIFF_SEQ_THREE, allFormState, currentEntity?.tariffSeq3, isRead, isSaveClicked, tariffCodeDtlVM])
                
        const memoTarScheme = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
                <CommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentEntity?.tariffSchemeCode || ''}
                    fieldValue={currentEntity?.tariffSchemeCode}
                    fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_SCHEME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'tariffSchemeCode'}
                    isShowMissingError={true}
                    maxLength={60}
                    options={dynamicOptions.tariffSchemeDropdownOptions}
                    requiredFieldList={tariffCodeMainRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />        
            </div>
        , [TARIFF_CODE_CONSTANT.TARIFF_SCHEME, allFormState, currentEntity?.tariffSchemeCode, dynamicOptions.tariffSchemeDropdownOptions, isRead, isSaveClicked, tariffCodeDtlVM])   

        const memoNature = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "24px" }}>
                {/* <InputDropdown
                    label={TARIFF_CODE_CONSTANT.TARIFF_NATURE}
                    width='395px'
                    inputType="freeText"
                    mode={'multiple'}
                    sort={false}
                    disabled={isRead}
                    value={currentEntity.tariffNatureList?.map((item) => ({
                        value: item
                    }))}
                    onChange={(e) => tariffCodeDtlVM.onHeaderMultipleDropdownChange(e, 'tariffNatureList')}
                    options={dynamicOptions.tariffNatureDropdownOptions}/> */}

            <CommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffNature && currentEntity?.tariffNature?.length <=20 ? currentEntity?.tariffNature : (currentEntity?.tariffNature?.substring(0, 20) + '...') || ''}
                fieldValue={currentEntity?.tariffNatureList}
                fieldLabel={TARIFF_CODE_CONSTANT.TARIFF_NATURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffNatureList'}
                maxLength={600}
                isMultipleDropDown={true}
                options={dynamicOptions.tariffNatureDropdownOptions}
                requiredFieldList={tariffCodeMainRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffCodeDtlVM.onHeaderMultipleDropdownChange(fieldKey, fieldValue && fieldValue as string[], fFullValue)} />
                
            </div>
            , [allFormState, isRead, currentEntity?.tariffNature, currentEntity?.tariffNatureList, TARIFF_CODE_CONSTANT.TARIFF_NATURE, isSaveClicked, dynamicOptions.tariffNatureDropdownOptions, tariffCodeDtlVM])
      
        const memoPercentage = useMemo(() =>
            <div className='im-flex-row-item' style={{width:"180px", marginBottom:"24px"}}>            
                <NumberInputComponent
                    label={TARIFF_CODE_CONSTANT.PERCENTAGE}
                    maxLength={60}
                    fieldName={"percentage"}
                    value={currentEntity?.percentage || ''}
                    errorMessage={''}
                    disabled={isRead}
                    decimalPlaces={2}
                    onChange={(e: any, fieldKey: string, fieldValue: any) =>onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}/>        
            </div>
        , [TARIFF_CODE_CONSTANT.PERCENTAGE, currentEntity?.percentage, isRead, onNumberFieldChange])   


        const memoParentTarType = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <CommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parentTariffType || ''}
                fieldValue={currentEntity?.parentTariffType}
                fieldLabel={TARIFF_CODE_CONSTANT.PARENT_TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'parentTariffType'}
                isShowMissingError={false}
                maxLength={60}
                options={memoParentTariffTypeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> 
            </div>
            , [TARIFF_CODE_CONSTANT.PARENT_TARIFF_TYPE, currentEntity?.parentTariffType, isRead, isSaveClicked, memoParentTariffTypeOptions, tariffCodeDtlVM])
    
        const memoParentTarCode = useMemo(() =>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <CommonField
                errorMessages={{}}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.parentTariffCode || ''}
                fieldValue={currentEntity?.parentTariffCode}
                fieldLabel={TARIFF_CODE_CONSTANT.PARENT_TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'parentTariffCode'}
                isShowMissingError={false}
                maxLength={60}
                options={memoParentTariffCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    tariffCodeDtlVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> 
            </div>
            , [TARIFF_CODE_CONSTANT.PARENT_TARIFF_CODE, currentEntity?.parentTariffCode, isRead, isSaveClicked, memoParentTariffCodeOptions, tariffCodeDtlVM])

        const memoActiveInd = useMemo(() =>
            <>
            <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
                <HPHGroupRadioButton
                    label={TARIFF_CODE_CONSTANT.ACTIVE_IND}                
                    disabled={false}
                    radioOptions={yesOrNoRadioOptions}
                    value={currentEntity.activeInd??''}
                    onChange={(e) => tariffCodeDtlVM.onRadioChange(e,'activeInd')}
                    checked={yesOrNoRadioOptions.find(item => item.key === currentEntity.activeInd)?.name}/>
            </div>
            </>
        , [TARIFF_CODE_CONSTANT.ACTIVE_IND, currentEntity.activeInd, tariffCodeDtlVM])

        const memoTputInd = useMemo(() =>
            <>
            <div className='im-flex-row-item'>
                <HPHGroupRadioButton
                    label={TARIFF_CODE_CONSTANT.INCLUDE_IN_THROUGHPUT}                
                    disabled={false}
                    radioOptions={yesOrNoRadioOptions}
                    value={currentEntity.includeTputInd??''}
                    onChange={(e) => tariffCodeDtlVM.onRadioChange(e,'includeTputInd')}
                    checked={yesOrNoRadioOptions.find(item => item.key === currentEntity.includeTputInd)?.name}/>
            </div>
            </>
        , [TARIFF_CODE_CONSTANT.INCLUDE_IN_THROUGHPUT, currentEntity.includeTputInd, tariffCodeDtlVM])

    const isSaveDisable = () => {
        return false;
    }

    const handleEdit = useCallback(()=>{
        tariffCodeDtlVM.onEdit(currentEntity);
    },[currentEntity, tariffCodeDtlVM])

    const handleSave = useCallback(async () => {
        setIsLoading(true);
        try {
            tariffCodeDtlVM.onSaveClicked();
            const res = await tariffCodeDtlVM.onSave(currentEntity,isAdd);
            if(res && res["mandatoryCheckFail"]){
                messageBarVM.showError(res["mandatoryCheckFail"]?.toString());
            }else if(res){
                messageBarVM.showError(res);
            }
        } catch (error) {
            console.log('Save data failed.')
        }finally{
            setIsLoading(false)
        }
        
    },[currentEntity, isAdd, messageBarVM, tariffCodeDtlVM]);


    return <>
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
                        <Sidebarheader style={{ width: '100%' }}>
                            <SidebarTitle>{!!currentEntity.tariffCode?currentEntity.tariffCode : "NEW TARIFF CODE"}</SidebarTitle>
                            {/* <SidebarActionCross>
                                <>
                                   <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />
                                    <IconButton fileName="Icon-tick" size="medium" disabled={isSaveDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />
                                </>
                            </SidebarActionCross> */}


                            <SidebarActionCross>
                                    {(!isAdd && (isEditable && isRead)) &&
                                        <>
                                            {
                                                (
                                                    (isEditable && allowUpdate)
                                                )
                                                && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                            }
                                        </>}

                                    {((isAdd || (isEditable && !isRead))) &&
                                        <>
                                        <IconButton fileName="Icon-reset" size="medium" toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={handleReset} />
                                            {
                                                (
                                                    (isAdd) ||
                                                    (isEditable && !isRead)
                                                )
                                                && <IconButton fileName="Icon-tick" size="medium" disabled={isSaveDisable()} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={handleSave} />
                                            }
                                        </>}
                                </SidebarActionCross>

                        </Sidebarheader>

                    </StyledSidebar>
                </div>
            </div>
            {isLoading && <Loader Indicator="Spinner" size="Medium" />}
            <div className={'add-edit-form'} style={{ height: '78vh', maxHeight: '78vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>

                        <CriteriaItemContainer>
                            {memoSeq}
                            {memoActiveInd}
                            {memoTputInd}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoTarType}
                            {memoTarCode}
                        </CriteriaItemContainer>
                        

                        <CriteriaItemContainer>
                            {memoParentTarType}
                            {memoParentTarCode}
                            {memoTarScheme}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTarCodeDesc}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTarCodeDescOther}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoNature}
                            {memoPercentage}
                        </CriteriaItemContainer>

                        <CriteriaItemContainer>
                            {memoTarGpIdOne}
                            {memoTarGpIdTwo}
                            {memoTarGpIdThree}
                        </CriteriaItemContainer>
                        
                        <CriteriaItemContainer>
                            {memoTarSeqOne}
                            {memoTarSeqTwo}
                            {memoTarSeqThree}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>

        </div>
    </>
}
