import { MasterDataRepoImpl } from "domain/repository/MasterData/MasterDataRepoImpl";
import { StandardTariffCodeRepoImpl } from "domain/repository/TariffCode/StandardTariffCodeRepoImpl";
import { TariffCodeComponentRepoImpl } from "domain/repository/TariffCode/TariffCodeComponentRepoImpl";
import { TariffComponentRepoImpl } from "domain/repository/TariffCode/TariffComponentRepoImpl";
import { TariffComponentValueRepoImpl } from "domain/repository/TariffCode/TariffComponentValueRepoImpl";
import { useTariffCodeDetailTracked } from "presentation/store/TariffCode/TariffCodeDetailProvider";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { TariffCodeDetailVM } from "presentation/viewModel/TariffCode/TariffCodeDetailVM";
import { useMemo } from "react";

export const useTariffCodeDetailVM = () => {
    const [, setTariffCodeDetailState] = useTariffCodeDetailTracked();
    const [, setTariffCodeState] = useTariffCodeTracked();
    const tariffCodeDetailVM = useMemo(() =>
        TariffCodeDetailVM({
            dtlDispatch: [setTariffCodeDetailState],
            dispatch: [setTariffCodeState],
            standardTariffCodeRepo:StandardTariffCodeRepoImpl(),
            tariffComponentRepo: TariffComponentRepoImpl(),
            tariffComponentValueRepo: TariffComponentValueRepoImpl(),
            tariffCodeComponentRepo: TariffCodeComponentRepoImpl(),
            masterDataRepo: MasterDataRepoImpl(),
        }), [setTariffCodeDetailState, setTariffCodeState])

    return tariffCodeDetailVM
}