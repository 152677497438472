import { useTariffCodeDetailVM } from "presentation/hook/TariffCode/useTariffCodeDetailVM";
import { useTariffCodeTracked } from "presentation/store/TariffCode/TariffCodeProvider";
import { memo, useCallback, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import { TariffCodeHeaderFormPanel } from "./Form/TariffCodeHeaderFormPanel";
import { TariffCodeDetailContentPanel } from "./TariffCodeDetailContentPanel";
import TariffCodeDetailTitleBar from "./TariffCodeDetailTitleBar";

const TariffCodeDetailMaintenance: React.FC = () => {
    const [tariffCodeState] = useTariffCodeTracked();
    const tariffCodeDtlVM = useTariffCodeDetailVM();
    const [isLoading, setIsLoading] = useState(false);

    const tarCodeEty = tariffCodeState.currentSelectedRow;

    const initialScreen = useCallback(async () => {
        setIsLoading(true);
        try {
            const results = await Promise.allSettled([
                tariffCodeDtlVM.loadDropdownOption(),
                tariffCodeDtlVM.onPageInit(tarCodeEty),
            ]);
            results.forEach((result, index) => {
                if (index === 0 && result.status === 'fulfilled') {
                    console.log(`Load TariffCodeDetailMaintenance successful!`);
                } else if (index === 1 && result.status === 'rejected') {
                    console.error(`Load dropdown options successful!`);
                }
            });
            setIsLoading(false);
        } catch (error) {
        }
    }, [tarCodeEty, tariffCodeDtlVM]);

    useEffect(() => {
        initialScreen().then(()=>{
            tarCodeEty?.id && tariffCodeDtlVM.onSearch(tarCodeEty.id);
        });
    }, [initialScreen, tarCodeEty.id, tariffCodeDtlVM])


    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper">
            <TariffCodeDetailTitleBar />
            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={"38%"}
                rightSectionWidth={"62%"}
                leftChildren={<TariffCodeHeaderFormPanel />}
                rightChildren={<TariffCodeDetailContentPanel />}
            />
        </div>
    </>
}

export default memo(TariffCodeDetailMaintenance);